import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MultiCheckbox = _resolveComponent("MultiCheckbox")!
  const _component_QuestionWrapper = _resolveComponent("QuestionWrapper")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createVNode(_component_QuestionWrapper, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, {
            title: _ctx.title,
            subtitle: "Please leave blank if not applicable"
          }, null, 8, ["title"]),
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_MultiCheckbox, {
              questionKey: _ctx.questionNumber,
              options: _ctx.options,
              onAnswered: _ctx.onAnswered,
              title: _ctx.title
            }, null, 8, ["questionKey", "options", "onAnswered", "title"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}