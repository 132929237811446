
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  MultiCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    MultiCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 8;
    const router = useRouter();
    const title = "Are you currently taking any of the following medications?";
    const options = [
      {
        label: "Antibiotics for TB - Rifampin, Rifabutin",
        value: "Antibiotics for TB - Rifampin, Rifabutin",
      },
      {
        label:
          "Antiseizure medicines - Carbamezepine, Felbamate, Oxcarbazepine, Phenytoin, Primidone, Rufinamide, Topiramate, Lamotrigine, Barbituates",
        value:
          "Antiseizure medicines - Carbamezepine, Felbamate, Oxcarbazepine, Phenytoin, Primidone, Rufinamide, Topiramate, Lamotrigine, Barbituates",
      },
      {
        label: "Herbal Remedies - St John's Wort",
        value: "Herbal Remedies - St John's Wort",
      },
      {
        label: "None of the above",
        value: "NIL",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      title,
      questionNumber,
    };
  },
});
